import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, ViewChild, ViewEncapsulation } from "@angular/core";
import _ from "lodash";

import { GridSettings } from "../../_models/grid-settings.interface";
import { process, State } from "@progress/kendo-data-query";

import { GridDataResult, DataStateChangeEvent, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";

import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { DataService } from "../../services/data.service";
import { DashboardService } from "../../services/dashboard.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { Global } from "../../_constants/global.variables";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { ITag } from "../../_models/tag.model";
import { IGlobal } from "../../_models/global.model";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { ISystem } from "../../_models/system.model";
import { IAsset } from "../../_models/asset.model";
import { ISite } from "../../_models/site.model";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ISQLServerSubject } from "../../_models/sql-server-subject.model";

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: "lib-sabel-data",
	templateUrl: "./sabel-data.component.html",
	styleUrls: ["./sabel-data.component.scss"]
})
export class SabelDataComponent implements OnInit, OnDestroy {
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild("eventDataRowForCalculation")
	eventDataRowForCalculation: ElementRef;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	eventDataRowHeight: any = 47;
	eventDataPageSize: number;
	assetTagIds: any;
	isLoading: boolean;
	timeZoneType: any;
	public global: IGlobal = Global;
	theme: string;
	sabelData: any;
	fullDataCacheSubscription: Subscription;
	signalRTagUpdateSubscription: Subscription;
	widgetIsInViewSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	dashboardTimeZoneChangedSubscription: Subscription;
	private lastUpdateTimeinMilliseconds: number;
	private requestToRunInitializeFunction: boolean = false;
	private initialLoad: boolean = true;
	private componentName: string = "sabel-data: ";

	public options: any = [];
	public selected: any;
	public selectedSite: any;
	public selectedSystem: any;
	public selectedAsset: any;

	public navigationOpened: boolean = false;

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: []
			},
			take: 15
		},
		columnsConfig: [
			{
				field: "chart",
				title: "Chart",
				filterable: false,
				_width: 5,
				hidden: false
			},
			{
				field: "Id",
				title: "Id",
				_width: 10,
				filterable: true,
				hidden: Global.isMobile
			},
			{
				field: "Site",
				title: "Site",
				_width: Global.isMobile ? 12 : 5,
				filterable: true
			},
			{
				field: "System",
				title: "System",
				_width: Global.isMobile ? 12 : 5,
				filterable: true
			},
			{
				field: "Asset",
				title: "Asset",
				_width: Global.isMobile ? 12 : 5,
				filterable: true
			},
			{
				field: "JBTStandardObservationId",
				title: "Std Obs Id",
				_width: 5,
				filterable: true,
				hidden: Global.isMobile
			},
			{
				field: "Name",
				title: "Name",
				_width: Global.isMobile ? 10 : 15,
				filterable: true
			},
			{
				field: "Value",
				title: "Value",
				_width: Global.isMobile ? 3 : 10,
				filterable: true
			},
			{
				field: 'RedisKey',
				title: 'Redis Key',
				filterable: true,
				_width: 15,
			},
			{
				field: 'UserDateFull',
				title: 'User Time',
				filterable: true,
				_width: 20,
				filter: 'date',
				hidden: Global.isMobile
			},
			{
				field: 'SiteDateFull',
				title: 'Site Time',
				filterable: true,
				filter: 'date',
				_width: 20,
				hidden: Global.isMobile
			},
			{
				field: 'UTCDateFull',
				title: 'UTC Time',
				filterable: true,
				filter: 'date',
				_width: Global.isMobile ? 10 : 20,
			},
			{
				field: "TagId",
				title: "Tag Id",
				_width: 10,
				filterable: true,
				hidden: Global.isMobile
			}
		]
	};
	public guid: string;

	constructor(public dataService: DataService, private dashboardService: DashboardService, private kendoSettingsService: KendoSettingsService, private ref: ChangeDetectorRef, public signalR: SignalRCoreService, public utilityService: UtilityService) { }

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isLoading = true;
		console.log("this.widgetObject = %O", this.widgetObject);
		this.buildNavigationOptionsArray();
		setInterval(() => {
			this.clearRecentlyUpdatedStatus();
		}, 2000);

		setInterval(() => {
			if (this.requestToRunInitializeFunction) {
				this.initialize();
			}
		}, 1000);

		if (!_.isNil(this.widgetObject.WidgetSiteId)) {
			if (Global.FullDataCacheExists === false) {
				this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.theme = Global.Theme;
						this.initialize();
						this.initialLoad = false;
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
			} else {
				this.theme = Global.Theme;
				this.initialLoad = false;
				this.initialize();
			}
		} else {
			this.isLoading = false;
			this.initialLoad = false;
			return;
		}

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription = this.dashboardTimeZoneChanged.subscribe((data) => {
				console.log(data);
				let foundWidgetWithSameWidgetId = data.find((widgetThatWasChanged) => {
					return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId;
				});

				if (!_.isNil(foundWidgetWithSameWidgetId)) {
					console.log("Widget Time Zone Changed");
					this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);

					const localDateCol = this.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
					const siteDateCol = this.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
					const utcDateCol = this.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

					localDateCol.hidden = this.timeZoneType !== "User Time";
					siteDateCol.hidden = this.timeZoneType !== "Site Time";
					utcDateCol.hidden = this.timeZoneType !== "UTC Time";
					localDateCol.includeInChooser = !localDateCol.hidden;
					siteDateCol.includeInChooser = !siteDateCol.hidden;
					utcDateCol.includeInChooser = !utcDateCol.hidden;
				}
			});
		}
	}

	initialize() {
		this.isLoading = true;
		this.signalR.joinGroups();
		
		var sql = "API.Sabel_GetDataForWidget @SiteId=" + (this.widgetObject?.WidgetSiteId == null ? "null" : this.widgetObject.WidgetSiteId);
		if (this.widgetObject.WidgetSystemId != null) {
			var systemName = this.dataService.cache.systemsObject[this.widgetObject.WidgetSystemId].Name;
			sql += ", @SystemName='" + systemName + "'";
		}
		if (this.widgetObject.WidgetAssetId != null) {
			var assetName = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId].Name;
			sql += ", @AssetName='" + assetName + "'";
		}

		this.dataService.SQLActionAsPromise(sql).then((data: any) => {
			data.forEach((item: any) => {
				item.UserDateFull = new Date(item.ObservationDateTimeMS),
				item.UTCDateFull = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(item.ObservationDateTimeMS, 0),
				item.SiteDateFull = item.ObservationDateTimeMS ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(item.ObservationDateTimeMS, this.dataService.cache.sitesObject[+this.widgetObject.WidgetSiteId]?.UTCTimeOffset) : null; // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
				item.RecentlyUpdated = false
			});

			this.dataService.cache.sabelData = data;
			this.sabelData = this.dataService.cache.sabelData;

			console.log("sabelData = %O", this.sabelData);

			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.sabelData);
			}

			this.widgetObject.isDisplayDataLive = true;
			this.finishInitializingWidget();
		});

	}

	getSignalRUpdates() {

		this.dataService
			.createSQLUpdateSubjectAndSubscribe({ Id: this.guid, 
												  cachedCollectionName: "sabelData",
												  sqlUpdateName: "SabelData"
			})
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current SQL Active Subjects: %O", this.dataService.sqlActiveSubjects);
				var sqlActiveSubject = this.dataService.sqlActiveSubjects.firstOrDefault((subject:ISQLServerSubject) => { return subject.Id == this.guid });
				sqlActiveSubject && sqlActiveSubject.Subject$.subscribe((signalRData: any) => {
					this.updateGridData(signalRData);
				});
			});
	}
	finishInitializingWidget() {
		this.widgetIsInViewSubscription = this.dashboardService.widgetInViewChanged$.subscribe((data: any) => {
			if (data.widgetId === this.widgetObject.WidgetId) {
				if (this.eventDataRowForCalculation !== undefined) {
					this.eventDataRowHeight = this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
				}
				// this.isWidgetInView = data.isInView;
			}
		});

		if (this.widgetObject.WidgetKendoUIJson) {
			let jsonObjectParsed = this.kendoSettingsService.parseReturnedSettingsForDates(this.widgetObject.WidgetKendoUIJson);
			//the first item in the array is the gridSettings for the first tab of data for GSE-Overview

			let returnedParsedObject = jsonObjectParsed[0];
			//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
			this.gridSettings = this.mapGridSettings(this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.gridSettings, returnedParsedObject));
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.sabelData);
			}
			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget([this.gridSettings.columnsConfig], this.widgetObject.WidgetId);
		} else {
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.sabelData);
			}
		}
		this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);
		this.requestToRunInitializeFunction = false;

		this.isLoading = false;
	}

	saveGridSettings() {
		if (this.widgetObject !== undefined) {
			this.kendoSettingsService
				.saveGridSettings(
					[
						{
							gridObject: this.tagDataGrid.kendoGridParent,
							gridState: this.gridSettings.state
						}
					],
					this.widgetObject.WidgetId
				)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}


	processSignalRObservation(observation: any) {
		var countOfObservations = Global.SignalR.countOfObservations;
		if (countOfObservations == undefined) {
			countOfObservations = 1;
		}
		else {
			countOfObservations++;
		}
		Global.SignalR.countOfObservations = countOfObservations;
		this.signalR.countOfSignalRObservations$.next(countOfObservations);
		let signalRData = this.dataService.GetJsonFromSignalR(observation);
		//Global.User.DebugMode && console.log(this.componentName + "processSignalRObservation observation = %O", observation.object != undefined ? observation.object : observation);
		if (observation?.groupName == "Sabel_" + this.widgetObject.WidgetSiteId) {
			this.updateGridData(signalRData);
		}
	}

	isIncomingSignalRMessageApplicableToList(signalRData: any): boolean {
		var appliesToList: boolean = false;
		var recordExists = this.sabelData.firstOrDefault((data: any) => { return data.Id == signalRData.Id });
		if (!recordExists) {
			if (this.selectedSystem != null || this.selectedAsset != null) {
				appliesToList = this.selectedSystem == null || (signalRData.System == this.selectedSystem?.Name && (this.selectedAsset == null || (this.selectedAsset && signalRData.Asset == this.selectedAsset?.Name)));
			}
			else
			{
				appliesToList = true;
			}
		}
		return appliesToList;
	}

	updateGridData(signalRData: any) {

		if (this.isIncomingSignalRMessageApplicableToList(signalRData)) {
			signalRData.UserDateFull = new Date(signalRData.ObservationDateTimeMS),
			signalRData.UTCDateFull = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.ObservationDateTimeMS, 0),
			signalRData.SiteDateFull = signalRData.ObservationDateTimeMS ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.ObservationDateTimeMS, this.dataService.cache.sitesObject[+this.widgetObject.WidgetSiteId]?.UTCTimeOffset) : null; // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
			signalRData.RecentlyUpdated = true;

			this.sabelData.unshift(signalRData);
			this.sabelData.splice(1000);

			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.sabelData);
			}
		}

	}

	clearRecentlyUpdatedStatus() {
		var recentlyUpdated = this.sabelData?.where((data: any) => { return data.RecentlyUpdated == true }).toArray();
		if (recentlyUpdated != null) {
			recentlyUpdated.forEach((item: any) => {
				item.RecentlyUpdated = false;
			});

			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.sabelData);
			}
		}
	}

	onResized(event) {
		this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
	}

	public mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
			gridData: this.dataService.cache !== undefined ? process(this.sabelData, state) : emptyArray
		};
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log("ngOnDestroy invoked...");
		// if (!_.isNil(this.widgetGroupSettings)) {
		// 	this.signalR.leaveParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
		// }
		this.signalRTagUpdateSubscription && this.signalRTagUpdateSubscription.unsubscribe();
		this.fullDataCacheSubscription && this.fullDataCacheSubscription.unsubscribe();
		this.dashboardTimeZoneChangedSubscription && this.dashboardTimeZoneChangedSubscription.unsubscribe();

		this.widgetIsInViewSubscription && this.widgetIsInViewSubscription.unsubscribe();
	}

	private buildNavigationOptionsArray() {
		var navigationWidth = Global.isMobile ? "90px" : "105px";
		this.selectedSite = this.dataService.cache.sitesObject[this.widgetObject.WidgetSiteId];
		this.selectedSystem = this.dataService.cache.systemsObject[this.widgetObject.WidgetSystemId];
		this.selectedAsset = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId];

		var viewAllSites = {
			id: 10,
			width: "105px",
			name: "View All Sites",
			selected: this.selectedSite == null ? true : false,
			action: () => this.changeSite(null)
		};
		if (viewAllSites.selected) {
			this.changeSite(null);
		}

		this.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => this.widgetObject.editWidgetName(this.widgetObject)
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => this.dashboardService._reloadedWidget.next(this.widgetObject)
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => this.widgetObject.deleteFunction()
					}
				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Navigation",
				children: Global.User.PermittedSites
					.where((site: ISite) => {
						return site.SabelSite == true && site.Active == true;
					})
					.orderBy((site: ISite) => {
						return site.Name;
					})
					.select((site: ISite) => {

						var newSite = {
		 					id: 10,
							width: navigationWidth,
							name: site.Name,
		 					selected: this.selectedSite?.Id == site.Id || this.selectedSystem?.Site?.Id == site.Id || this.selectedAsset?.Site?.Id == site.Id,
		 					action: () => this.changeSite(site),
							children: site.Systems?.where((system:ISystem) => {
										return system.TypeId == 3 || system.TypeId == 15 || system.TypeId == 17
									})
									.where((system:ISystem) => {
										return system.Assets.length > 0
									})
									.orderBy((system:ISystem) => {
										return this.utilityService.GetGateNameSortValue(system.Name)
									})
									.thenByDescending((system:ISystem) => {
										return system.TypeId
									})
									.select((system: ISystem) => {
 										var newSystem = {
											id: 11,
											width: navigationWidth,
											name: system.Name,
											selected: this.selectedSystem?.Id == system.Id,
											action: () => this.changeSystem(system),
											children: system.Assets?.where((asset:IAsset) => {
													return asset.AssetTypeId != 917 && asset.AssetTypeId !=	918 && asset.AssetTypeId !=	6097 && asset.AssetTypeId != 6116 && asset.AssetTypeId != 86301 && asset.AssetTypeId !=	86303
												})
												.orderBy((asset:IAsset) => {
													return asset.Name
												})
												.thenByDescending((asset:IAsset) => {
													return asset.AssetTypeId
												})
												.select((asset: IAsset) => {
													var newAsset = {
														id: 12,
														width: navigationWidth,
														name: asset.Name,
														selected: this.selectedAsset?.Id == asset.Id,
														action: () => this.changeAsset(asset)
													};
													if (newAsset.selected) {
														this.changeAsset(asset);
													}
													return newAsset;
											}).toArray()
										}
										if (newSystem.selected) {
											this.changeSystem(site);
										}
										return newSystem;
								}).toArray()
						}
						if (newSite.selected) {
							this.changeSite(site);
						}
						return newSite;
					})
					.toArray(),
				root: true,
				opened: this.selectedSite != null || this.selectedSystem != null || this.selectedAsset != null ? false : true //--only displaying the initial navigation menu if no item has been selected.
			}
		];

		if (Global.isMobile) {
			this.options = this.options.where((item: any) => { return item.id != 1 }).toArray(); //--removing Settings tab for mobile.
		}
		var navigationOption = this.options.firstOrDefault((option:any) => { return option.name == 'Navigation'}); 
		navigationOption.children.push(viewAllSites);

		console.log("this.options = %O", this.options);
	}

	changeSite(site: any) {
		if (!this.initialLoad) {
			this.signalR.leaveAdditionalGroup("Sabel_" + this.widgetObject.WidgetSiteId);
			this.widgetObject.WidgetSiteId = site?.Id;
			this.widgetObject.WidgetSystemId = null;
			this.widgetObject.WidgetGateSystemId = null;
			this.widgetObject.WidgetAssetId = null;
			this.selectedSite = site;
			this.selectedSystem = null;
			this.selectedAsset = null;
			this.saveWidgetObject("SiteId", site?.Id);
			this.saveWidgetObject("SystemId", null);
			this.saveWidgetObject("GateSystemId", null);
			this.saveWidgetObject("AssetId", null);
			this.widgetObject.siteChange();
			this.widgetObject.systemChange();
			this.widgetObject.assetChange();
			this.requestToRunInitializeFunction = true;
		}
		else {
			this.selectedSite = this.widgetObject.WidgetSiteId == null ? null : this.dataService.cache.sitesObject[this.widgetObject.WidgetSiteId];
		}

	}

	changeSystem(system: any) {
		if (!this.initialLoad) {
			this.widgetObject.WidgetSystemId = system.Id;
			this.widgetObject.WidgetGateSystemId = system.Id;
			this.widgetObject.WidgetAssetId = null;
			this.selectedSystem = system;
			this.selectedAsset = null;
			this.saveWidgetObject("SystemId", system.Id);
			this.saveWidgetObject("GateSystemId", system.Id);
			this.saveWidgetObject("AssetId", null);
			this.widgetObject.systemChange();
			this.widgetObject.assetChange();
			this.requestToRunInitializeFunction = true;
		}
		else {
			this.selectedSystem = this.dataService.cache.systemsObject[this.widgetObject.WidgetSystemId];
		}
	}

	changeAsset(asset: any) {
		if (!this.initialLoad) {
			this.widgetObject.WidgetAssetId = asset.Id;
			this.selectedAsset = asset;
			this.saveWidgetObject("AssetId", asset.Id);
			this.widgetObject.assetChange();
			this.requestToRunInitializeFunction = true;
		}
		else {
			this.selectedAsset = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId];
		}
	}

	public checkNavigation(opened: any) {
		console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		console.log("checkSelectedItem invoked. selected = %O", selected);
		this.signalR.LogActivity("Chose '" + selected.name + "' in the navigation of the " + this.widgetObject.WidgetTypeName + " widget.");
	}

	saveWidgetObject(fieldName: string, fieldValue: string) {
		this.dataService.SQLActionAsPromise("API.Widget_UpdateRecordByIdAndFieldName " + this.widgetObject.WidgetId + ", '" + fieldName + "', '" + fieldValue + "'").then((data: any) => {
			Global.User.DebugMode && console.log("data updated: %O", data);
			this.signalR.LogActivity("Updated '" + fieldName + "' = " + fieldValue + " for the " + this.widgetObject.WidgetTypeName + " (WidgetId: " + this.widgetObject.WidgetId + ") widget.");

		});
	}
}
